html,
body,
#App {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  height: 100%;
}
#App{
  background-image: url('./images/opacity50_bg.svg');
} 

#brand-reserved{
  padding: 0;
  margin: 1rem 0 0 0  !important;
  font-size: 12px;
}

.grid-container { 
  width: 100%; 
  position: absolute; 
  bottom: 0; 
  left: 0; 
  z-index:0;
}

.grid-container:after { 
  transform: perspective(200px) rotateX(35deg) scale(3,1) translateZ(0);
  content: ""; 
  display: block; 
  position: absolute; 
  bottom: 0; 
  left: 0; 
  right: 0; 
  width: 100%; 
  height: 100vh;
  padding: 1px; 
  -webkit-background-clip: content-box; 
  -webkit-backface-visibility: hidden;
  outline: 1px solid transparent;
  transform-origin: bottom center;
  will-change: transform; 
}

.grid-container:after {
  background-position: center bottom;
  background-size: 40px 40px;
  background-image: 
  linear-gradient(to right, black 2px, transparent 2px), 
  linear-gradient(to bottom, black 1px, transparent 2px);
}

#contact-info{
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
}

#contact-info div{
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  background-color: #0b8bcd;
  color:white;
  padding: .5rem 1rem;
  border-radius: 5px;
  margin: 0 1rem;
}
#contact-info div:hover{
  cursor: pointer;
  background-color: black;
}

#contact-info a{
  color:white;
  text-decoration: none;
}

#details-modal{
  position: absolute;
  display:none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  min-height: 100%;
  z-index: 10;
  height: 100%;
  background-color: #0b8bcd;
  background-image: url('./images/optec-bc-fav.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}


.modal-wrap{
  display:flex;
  width:50%;
  flex-flow: column nowrap;
  background-color: white;
  align-items: center;
  border-radius: 10px;
  padding: 2rem;
  margin: auto ;
}

.modal-wrap tbody{
  margin:auto;
}


#scalingCol {
  position: relative;
}


/*This needs to have 2 versions of itself, one for regular 
 *and one for when it's in the fixed wall, which probably changes
 it to be a class and not an id
 * */
#modules {
  position:absolute;
  display: flex;
  z-index: 2;
  top: 50%;
  left:50%;
  display:flex;
/*  background-color: rgb(255 255 255); */
  background-color: pink;
  transform-origin: top left;
  transform: translate(-50%, -50%);
}

#fixedWall{
  position: absolute;
  top:55.5%;
  left:50%;
  z-index: -1;
  background-color: rgb(0,0,255);
  transform-origin: top left;
  z-index: 2;
  transform: translate(-50%, -50%);
}

#menu{
  color:white;
  font-weight: bold;
  background-color: #0b8bcd;
  overflow: hidden scroll;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  padding:1rem;
  width:400px;
  z-index: 10;
}

#stats{
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  top:0;    
  right:0;
  padding: 0.5rem;
  margin: 0.5rem;
  justify-content: space-evenly;
  background-color: #088BCD;
  border-radius: 10px;
  color:white;
}

#man-col{
  position: relative;
  height:100vh;
}

#menu-tab{
  position: relative;
  top:50px;
  left:-20px;
  display:inline-flex;
  padding:1rem;
  transform: rotate(270deg);
  font-weight:bold;
  background-color: #0b8bcd;
  color:white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dimension-inputs input{
  margin-top: 1rem;
}

#toggle-tabs{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-apart;
  align-items: center;
  margin: 1rem 0 0 0;
}

#toggle-tabs * {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding:1rem;
  width:50%;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  text-align: center;
}

#module-select{
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 1rem;
  background-color: rgba(255,255,255,0.7);
}

#module-select * {
  margin-bottom: 1rem;
}

#toggle-tabs *:hover {
  cursor: pointer;
}

.active-tab {
  background-color: rgba(255,255,255,0.7);
  color:black;
}

#indoor-presets{
  display:flex;
  flex-flow: row wrap;
  align-items: center;
}

#outdoor-presets{
  display:flex;
  flex-flow: row wrap;
  align-items: center;
}

#indoor-presets *, #outdoor-presets *{
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(0,0,0,0.1);
  width:40%;
  padding: 1rem 0;
  margin: 0 auto 1rem auto;
}

  

#indoor-presets *:hover{
  cursor: pointer;
  background-color: rgba(0,0,0,0.2);
}

#menu-header{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

#menu-close-btn:hover, #menu-tab{
  cursor: pointer;
}

#lone-logo{
  position: absolute;
  opacity: 0.3;
  scale:2;
  left:0;
  z-index: -1;
}

#mod-wrapper {
  display:flex;
  background-color: #3E89C8;
  justify-content: center;
}

.mod {
  background-color: #E8E8E8;
  border: 1px dashed #000;
}

.mod-not-seamless{
  border: 1px solid black;
  background-color: gold;
}

.mod-not-seamless-with-extra-border{
  border: 2px solid red;
  background-color: gold;
}

.widthAndHeight {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: left;
}

.option-label {
  display: flex;
  margin: 2rem 0 1rem 0;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  font-size: 1.5rem;
}


.widthAndHeight>* {
  margin: 5px;
}

.sideBySide{
	display:flex;
	flex-flow:row nowrap;
	margin:1rem 3rem;
	align-items:center;
}

#forScaleMan {
  position: absolute;
  right:0px;  
  bottom:0px;
  transform-origin: bottom right;
  max-width: 100vw;
  max-height: 80vh;
  z-index: 3;
  height: auto;
}

@media screen and (max-width: 1440px){
  #forScaleMan{
    position: absolute;
    right: -100px !important;    
  }
}

.pulse{
  animation: pulse 3s infinite ease-in-out;
}

.stack-wrap{
  display:flex;
  flex-flow: column nowrap;
  width:19rem;
}

#wall-dims{
  margin-bottom:1rem;
}

#wall-dims, #screen-dims{
  display:flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}



@keyframes pulse{
  0%{
    opacity: 1;
  }
  50%{
    opacity:.5;
  }
  100%{
    opacity: 1;
  }
}
